import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './selectStrategy.css';  // Import CSS file
import { Link } from 'react-router-dom';
import { useUserContext } from '../../userContext';
import api from '../api/api';
import Navbars from '../Navbars';

const SelectStrategy = () => {
  const { userID } = useUserContext();  // Get userId from the route params
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscribedStrategyIds, setSubscribedStrategyIds] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (userID) {
      const fetchSubscribedStrategies = async () => {
        try {
          const response = await api.get('/get-user-subscriptions', {
            params: { userId: userID }
          });
          setSubscribedStrategyIds(response.data.map(strategy => strategy.Strategy_ID));
        } catch (err) {
          setError('Error fetching subscription data');
        }
      };

      fetchSubscribedStrategies();
    }

    const fetchStrategies = async () => {
      try {
        const response = await api.get('/select-strategy');
        setStrategies(response.data);
      } catch (err) {
        setError('Error fetching available strategies');
      } finally {
        setLoading(false);
      }
    };

    fetchStrategies();
  }, [userID]);

  const handleSubscribeUnsubscribe = async (strategyId, action) => {
    if (!userID) {
     
      return setError('');
    }

    try {
      const response = await api.get('/subscribe/strategy/allocation', {
        params: {
          userId: userID,
          strategyId: strategyId,
          action: action,
        }
      });

      if (response.data.message.includes('successfully')) {
        let updatedSubscribedStrategies;
        if (action === 'subscribe') {
          updatedSubscribedStrategies = [...new Set([...subscribedStrategyIds, strategyId])];
        } else {
          updatedSubscribedStrategies = subscribedStrategyIds.filter(id => id !== strategyId);
        }

        setSubscribedStrategyIds(updatedSubscribedStrategies);
        localStorage.setItem('subscribedStrategies', JSON.stringify(updatedSubscribedStrategies));
      }
    } catch (err) {
      console.log(err.response.data.msgCode);
      if (err.response.data.msgCode==="AddBroker") {
        alert("Please add broker");
        navigate("/algo-strategy/add-broker")
      }
      setError(`Error ${action === 'subscribe' ?  'Please add broker': 'unsubscribing'} from strategy`);
      console.error(err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="d-flex">
      <Navbars />
      <div>
      <h3 className='text-center'>Select Strategy</h3>

      </div>

      {/* <div id="strategy" className="container">
        <table className="table">
          <thead>
            <tr>
              <th>Strategy ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {strategies.map((strategy) => (
              <tr key={strategy.Strategy_ID}>
                <td>{strategy.Strategy_ID}</td>
                <td>{strategy.Name}</td>
                <td>{strategy.Description}</td>
                <td>
                  {subscribedStrategyIds.includes(strategy.Strategy_ID) ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => handleSubscribeUnsubscribe(strategy.Strategy_ID, 'unsubscribe')}
                    >
                      Unsubscribe
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={() => handleSubscribeUnsubscribe(strategy.Strategy_ID, 'subscribe')}
                    >
                      Subscribe
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      
       <div className='main-sta-card'>
      
              <div className='' style={
                {
                  // margin:"auto"
                }
              }>
                {strategies.map((strategy) => <div key={strategy.Strategy_ID} className='strategy-card'>
      
                  <div>
                    <h2 className='text-center'>
                      {strategy.Name}
                    </h2>
                    <p align='center'>
                      Info: For Nutral <br></br>(sideways) market
                    </p>
                  </div>
                  {strategy.Name === "STRADDLE" && <div>
                    <h2 className='text-center'>
                      POSITIONS
                    </h2>
                    <p align='center'> 
                      1. CE ATM sell<br></br>
                      2. PE ATM SELL
                    
                    </p>
                  
                  </div>}{strategy.Name !== "STRADDLE" && <div>
                    <h2 className='text-center'>
                      POSITIONS
                    </h2>
                    <p align='center'> 
                      1. CE OTM2 sell<br></br>
                      2. PE OTM2 SELL
                    
                    </p>
                  </div>}
                  <div  align='center'>
                  {subscribedStrategyIds.includes(strategy.Strategy_ID) ? (
                    <button 
                    style={{marginTop:"4rem"}}
                      className="btn btn-danger"
                      onClick={() => handleSubscribeUnsubscribe(strategy.Strategy_ID, 'unsubscribe')}
                    >
                      Unsubscribe
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      style={{marginTop:"4rem"}}
                      onClick={() => handleSubscribeUnsubscribe(strategy.Strategy_ID, 'subscribe')}
                    >
                      Subscribe
                    </button>
                  )}
      
                  </div>
      
                </div >)}
              </div>
            </div>

      <Footer />
    </div>
  );
};

export default SelectStrategy;
