import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Navbars from './Navbars';

const Exceltohtmlwithfilter = () => {
    const [sheets, setSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [data, setData] = useState([]);
    const [filterValue, setFilterValue] = useState('');
  
    useEffect(() => {
      const fetchExcel = async () => {
        const response = await fetch('excel/option_chain_data_all_instrument.xlsx');  // Replace with your Excel file URL
        const blob = await response.blob();
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetNames = workbook.SheetNames;
          setSheets(sheetNames);
          setSelectedSheet(sheetNames[0]); // Default to the first sheet
        };
        fileReader.readAsArrayBuffer(blob);
      };
      fetchExcel();
    }, []);
  
    useEffect(() => {
      if (selectedSheet) {
        const fetchData = async () => {
          const response = await fetch('excel/option_chain_data_all_instrument.xlsx');  // Again, replace with your Excel file URL
          const blob = await response.blob();
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheet = workbook.Sheets[selectedSheet];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setData(jsonData);
            if (jsonData.length > 0) {
              const firstColumnKey = Object.keys(jsonData[0])[0];
              setFilterValue(jsonData[0][firstColumnKey]); // Default filter to the first value of the first column
            }
          };
          fileReader.readAsArrayBuffer(blob);
        };
        fetchData();
      }
    }, [selectedSheet]);
  
    const handleSheetChange = (e) => {
      setSelectedSheet(e.target.value);
    };
  
    // Get unique values from the first column
    const uniqueValues = [...new Set(data.map(row => row[Object.keys(row)[0]]))];
  
    // Filter data based on the first column and the selected filter value
    const filteredData = data.filter(row => {
      return row[Object.keys(row)[0]] === filterValue; // Filter by the first column
    });
  
    return (
      <div id='bnr'>
            <Navbars/>
      
      <div id="bnr-2">
        <label className='bnrlabel'>Select Stock :</label>
        <select onChange={handleSheetChange} value={selectedSheet}>
          {sheets.map(sheet => (
            <option key={sheet} value={sheet}>{sheet}</option>
          ))}
        </select>
        <label className='bnrlabel'>Expiry Date :</label>
        <select onChange={(e) => setFilterValue(e.target.value)} value={filterValue}>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>{value}</option>
          ))}
        </select>
  
        <table>
          <thead>
            <tr>
              <th>Call Volume</th> {/* 3 Column Header */}
              <th>Call OI</th> {/* 7 Column Header */}
              <th>Change in Call OI</th> {/* 8 Column Header */}
              <th>Call LTP</th> {/* 9 Column Header */}
              <th>Strike Price</th> {/* 11 Column Header */}
              <th>Put LTP</th> {/* 20 Column Header */}
              <th>Change in Put OI</th> {/* 20 Column Header */}
              <th>Put OI</th> {/* 21 Column Header */}
              <th>Put Volume</th> {/* 22 Column Header */}

            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row[Object.keys(row)[7]]}</td> {/* First Column Data */}
                <td>{row[Object.keys(row)[8]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[10]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[6]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[2]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[19]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[23]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[21]]}</td> {/* Fourth Column Data */}
                <td>{row[Object.keys(row)[20]]}</td> {/* Fourth Column Data */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    );
  };
  

export default Exceltohtmlwithfilter;