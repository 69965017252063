// src/components/SymbolCards.js
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
// Register the chart elements
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const SymbolCards = () => {
  const [symbolsData, setSymbolsData] = useState([]);
  const [selectedChart, setSelectedChart] = useState("Total OI");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1029);

  useEffect(() => {
    // Parse the CSV file and load data
    Papa.parse("excel/oicards_with_previous_close.csv", {
      download: true,
      header: true,
      complete: (result) => {
        setSymbolsData(
          result.data
            .map((row) => ({
              Symbol: row.Symbol,
              call_volume: parseInt(row.call_volume),
              call_oi: parseInt(row.call_oi),
              call_oi_diff: parseInt(row.call_oi_diff),
              put_volume: parseInt(row.put_volume),
              put_oi: parseInt(row.put_oi),
              put_oi_diff: parseInt(row.put_oi_diff),
              underlying_spot_price: parseFloat(row.underlying_spot_price),
              Min_Expiry_Date: row.Min_Expiry_Date,
              previous_close: parseFloat(row.previous_close),
            }))
            .filter((symbolData) => !isNaN(symbolData.underlying_spot_price)) // Filter out cards with missing spot price
        );
      },
    });

    // Resize event listener to detect screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1029);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getChartData = (symbolData) => {
    switch (selectedChart) {
      case "Total OI":
        return {
          labels: ["Call", "Put"],
          datasets: [
            {
              data: [symbolData.call_oi, symbolData.put_oi],
              backgroundColor: ["#3f51b5", "#ff9800"],
              barThickness: 20, // Adjust bar width here
            },
          ],
        };
      case "Change in OI":
        return {
          labels: ["Call", "Put"],
          datasets: [
            {
              data: [symbolData.call_oi_diff, symbolData.put_oi_diff],
              backgroundColor: ["#8bc34a", "#ff5722"],
              barThickness: 20, // Adjust bar width here
            },
          ],
        };
      case "Volume":
        return {
          labels: ["Call", "Put"],
          datasets: [
            {
              data: [symbolData.call_volume, symbolData.put_volume],
              backgroundColor: ["#3f51b5", "#e91e63"],
              barThickness: 20, // Adjust bar width here
            },
          ],
        };
      default:
        return {};
    }
  };

  return (
    <div>
      <div style={styles.buttonGroup}>
        {["Total OI", "Change in OI", "Volume"].map((option) => (
          <button
            key={option}
            onClick={() => setSelectedChart(option)}
            style={{
              ...styles.button,
              backgroundColor: selectedChart === option ? "#1976d2" : "#e0e0e0",
              color: selectedChart === option ? "#fff" : "#000",
            }}
          >
            {option}
          </button>
        ))}
      </div>
      {isMobile ? (
        // Render Swiper on mobile
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          style={{ zIndex: "0" }}
        >
          {symbolsData.map((symbolData, index) => (
            <SwiperSlide key={index}>
             
          <div style={styles.container}>
              <div key={index} style={styles.card}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "0.8rem",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {symbolData.Symbol} :{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        symbolData.previous_close !== 0 &&
                        (symbolData.underlying_spot_price -
                          symbolData.previous_close) /
                          symbolData.previous_close >
                          0
                          ? "green"
                          : "red",
                    }}
                  >
                    {symbolData.underlying_spot_price}
                  </span>
                </h5>

                <div style={styles.info}>
                
                  <div style={styles.infoItem}>
                    <strong>Previous Close: </strong>
                    {symbolData.previous_close.toFixed(2)}
                  </div>
                  <div
                    style={{
                      ...styles.infoItem,
                      color:
                        symbolData.previous_close !== 0 &&
                        (symbolData.underlying_spot_price -
                          symbolData.previous_close) /
                          symbolData.previous_close >
                          0
                          ? "green"
                          : "red",
                    }}
                  >
                    <strong>Change: </strong>
                    {symbolData.previous_close !== 0
                      ? (
                          ((symbolData.underlying_spot_price -
                            symbolData.previous_close) /
                            symbolData.previous_close) *
                          100
                        ).toFixed(2) + "%"
                      : symbolData.underlying_spot_price}
                  </div>
                </div>
                <div style={styles.chart}>
                  <Bar
                    data={getChartData(symbolData)}
                    height={300}
                    width={500}
                    options={{
                      responsive: true,
                      indexAxis: "y", // Horizontal bar chart
                      plugins: {
                        legend: {
                          display: false, // Disable legend display
                        },
                      },
                      scales: {
                        x: {
                          display: false, // Hide x-axis index
                          grid: {
                            display: false, // Remove gridlines from x-axis
                          },
                        },
                        y: {
                          display: true, // Show y-axis labels
                          labels: ["Call", "Put"], // Explicitly set the labels
                          grid: {
                            display: false, // Remove gridlines from y-axis
                          },
                        },
                      },
                      barPercentage: 0.9, // Reduces space between bars (default is 0.9)
                      categoryPercentage: 0.8, // Reduces space between bars (default is 0.8)
                    }}
                  />
                </div>
              </div>
            {/* ))} */}
          </div>
        {/* </div> */}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        // Render Desktop Layout (non-Swiper) for larger screens
        <div style={styles.desktopContainer}>
          <div style={styles.container}>
            {symbolsData.map((symbolData, index) => (
              <div key={index} style={styles.card}>
                <h5
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "0.8rem",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {symbolData.Symbol} :{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        symbolData.previous_close !== 0 &&
                        (symbolData.underlying_spot_price -
                          symbolData.previous_close) /
                          symbolData.previous_close >
                          0
                          ? "green"
                          : "red",
                    }}
                  >
                    {symbolData.underlying_spot_price}
                  </span>
                </h5>

                <div style={styles.info}>
                 
                  <div style={styles.infoItem}>
                    <strong>Previous Close: </strong>
                    {symbolData.previous_close.toFixed(2)}
                  </div>
                  <div
                    style={{
                      ...styles.infoItem,
                      color:
                        symbolData.previous_close !== 0 &&
                        (symbolData.underlying_spot_price -
                          symbolData.previous_close) /
                          symbolData.previous_close >
                          0
                          ? "green"
                          : "red",
                    }}
                  >
                    <strong>Change: </strong>
                    {symbolData.previous_close !== 0
                      ? (
                          ((symbolData.underlying_spot_price -
                            symbolData.previous_close) /
                            symbolData.previous_close) *
                          100
                        ).toFixed(2) + "%"
                      : symbolData.underlying_spot_price}
                  </div>
                </div>
                <div style={styles.chart}>
                  <Bar
                    data={getChartData(symbolData)}
                    height={300}
                    width={500}
                    options={{
                      responsive: true,
                      indexAxis: "y", // Horizontal bar chart
                      plugins: {
                        legend: {
                          display: false, // Disable legend display
                        },
                      },
                      scales: {
                        x: {
                          display: false, // Hide x-axis index
                          grid: {
                            display: false, // Remove gridlines from x-axis
                          },
                        },
                        y: {
                          display: true, // Show y-axis labels
                          labels: ["Call", "Put"], // Explicitly set the labels
                          grid: {
                            display: false, // Remove gridlines from y-axis
                          },
                        },
                      },
                      barPercentage: 0.9, // Reduces space between bars (default is 0.9)
                      categoryPercentage: 0.8, // Reduces space between bars (default is 0.8)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    flexWrap: "wrap",
    // Ensure buttons align properly on small screens
    gap: "10px", // Add gap between buttons
  },
  button: {
    margin: "0 5px",
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s, color 0.3s",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "0px",
  },
  desktopContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "0px",
  },
  card: {
    width: "175px",
    padding: "15px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    margin: "10px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  info: {
    marginBottom: "-15px",
  },
  infoItem: {
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  chart: {
    height: "90px",
    marginBottom: "-20px",
  },
  // Media Query for 393px or lower screens
  '@media (max-width: 393px)': {
    buttonGroup: {
      justifyContent: "space-between", // Align buttons evenly
      marginTop: "15px", // Adjust top margin
      marginBottom: "15px", // Adjust bottom margin
      gap: "5px", // Reduce the gap between buttons
    },
    button: {
      fontSize: "12px", // Reduce font size for small screens
      padding: "6px 12px", // Adjust button padding
    },
  },
};

// const style = {
//   buttonGroup: {
//     display: "flex",
//     justifyContent: "center",
//     marginBottom: "20px",
//   },
//   button: {
//     margin: "0 10px",
//     padding: "10px 20px",
//     border: "none",
//     borderRadius: "4px",
//     cursor: "pointer",
//     fontSize: "16px",
//   },
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     padding: "0px",
//   },
//   card: {
//     width: "280px",
//     padding: "10px",
//     border: "1px solid #ccc",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//     // margin: "10px",
//     backgroundColor: "#fff",
//   },
//   info: {
//     marginBottom: "15px",
//   },
//   infoItem: {
//     marginBottom: "8px",
//     fontSize: "14px",
//     fontWeight: "bold",
//   },
//   chart: {
//     height: "90px",
//     marginBottom: "-10px",
//     marginTop: "-15px"
//   },
// };
export default SymbolCards;
