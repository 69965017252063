import React from 'react'
import Navbars from './Navbars'
import Footer from './Footer'
import './hotStocks.css'
function Hotstocks() {
  return (
    <>
      <Navbars/>
       <div >
          <div>
          
        <div class="ag-format-container">
        <div class="ag-courses_box">
            <div class="ag-courses_item">
                <a href="#" class="ag-courses-item_link">
                    <div class="ag-courses-item_bg"></div>
                    <div class="ag-courses-item_title">
                    M&M
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    2,817.65
                    </span>
                    </div>
                </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                TECHM
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,603.65	
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                CIPLA
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,559.55
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                SBIN
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    821.20
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                DR Reddy	
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,259.60	
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                HCLTECH
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,763.00
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg">
                </div>
                <div class="ag-courses-item_title">
                INFY
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,724.50
                    </span>
                    </div>
            </a>
            </div>

            <div class="ag-courses_item">
            <a href="#" class="ag-courses-item_link">
                <div class="ag-courses-item_bg"></div>

                <div class="ag-courses-item_title">
                INDUSINDBK
                    </div>

                    <div class="ag-courses-item_date-box">
                    Prev Close:
                    <span class="ag-courses-item_date">
                    1,067.00
                    </span>
                    </div>
            </a>
            </div>

        </div>
        </div>
        </div>
        <Footer/>
        </div>
        </>
  )
}

export default Hotstocks
