import React, { useState } from 'react';
import aboutimage from '../images/loginForm.jpg';
import Navbars from './Navbars';
import Footer from './Footer';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import api from './api/api';

function About(props) {
  // State hooks for form data
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Error state for each field
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  // Helper function to validate password
  const isPasswordStrong = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;
    return passwordRegex.test(password);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Reset error messages
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setMobileNumberError('');
    setErrorMessage('');
  
    let valid = true;
  
    // Validate fields
    if (!name || name.length > 100) {
      setNameError('Name cannot be empty or longer than 100 characters.');
      valid = false;
    }
  
    if (!email || email.length > 100 || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address (max 100 characters).');
      valid = false;
    }
  
    if (!password || password.length > 100 || !isPasswordStrong(password)) {
      setPasswordError('Password must be between 8-100 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      valid = false;
    }
  
    if (!mobileNumber || mobileNumber.length !== 10 || isNaN(mobileNumber)) {
      setMobileNumberError('Mobile number must be exactly 10 digits.');
      valid = false;
    }
  
    if (!agree) {
      setErrorMessage('Please agree to the terms and conditions.');
      valid = false;
    }
  
    if (!valid) return;
  
    // Data to be sent in the API request
    const userData = {
      userName: name,
      email: email,
      password: password,
      mobileNumber: mobileNumber
    };
  
    try {
      const response = await api.post('/create/user', userData);
  
      // Check if the response is successful
      if (response.status === 200) {
        console.log('User registered successfully:', response.data); // Log the response data
        alert('Registration successful!');
        navigate('/Signin');  // Navigate to the sign-in page
      } else {
        // Handle API error response (non-200 status)
        setErrorMessage(response.data.message || 'Registration failed due to a server error.');
      }
    } catch (error) {
      // Catching unexpected errors during API request
      console.error('Error during registration:', error);
      setErrorMessage(error?.response?.data?.message);
    }
  };
  

  return (
    <div className="d-flex flex-column">
      <Navbars />
      <div className=" mt-5 flex-grow-1">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-11 col-md-10 col-sm-12 col-10 mt-4">
              <div className="card text-black shadow-sm">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 order-2 order-lg-1">
                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-0">Sign Up</p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>

                        {/* General Error Message Display */}
                        {errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        )}

                        <div className="d-flex flex-column mb-4">
                          <label className="form-label" htmlFor="form3Example3c">Email</label>
                          <input
                            type="email"
                            id="form3Example3c"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && <div className="text-danger">{emailError}</div>}
                        </div>

                        <div className="d-flex flex-column mb-4">
                          <label className="form-label" htmlFor="form3Example5c">Mobile Number</label>
                          <input
                            type="text"
                            id="form3Example5c"
                            className="form-control"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                          {mobileNumberError && <div className="text-danger">{mobileNumberError}</div>}
                        </div>

                        <div className="d-flex flex-column mb-4">
                          <label className="form-label" htmlFor="form3Example1c">User Name</label>
                          <input
                            type="text"
                            id="form3Example1c"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {nameError && <div className="text-danger">{nameError}</div>}
                        </div>

                        <div className="d-flex flex-column mb-4">
                          <label className="form-label" htmlFor="form3Example4c">Password</label>
                          <input
                            type="password"
                            id="form3Example4c"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {passwordError && <div className="text-danger">{passwordError}</div>}
                        </div>

                        <div className="form-check d-flex justify-content-start mb-3">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value={agree}
                            id="form2Example3c"
                            onChange={() => setAgree(!agree)}
                          />
                          <label className="form-check-label" htmlFor="form2Example3">
                            I agree all statements in <a href="#!">Terms of service</a>
                          </label>
                        </div>

                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <button type="submit" className="btn btn-primary btn-lg w-100">
                            Register
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-6 col-lg-6 d-flex align-items-center order-1 order-lg-2">
                      <img src={aboutimage} className="img-fluid" alt="Sample image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
