import React, { useState, useEffect, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import * as XLSX from 'xlsx'; 

const Toploosing = () => {
  const [data, setData] = useState([]); // Store the data
  const [lastDataHash, setLastDataHash] = useState(null); // Store the hash of the last data
  const [dataLoaded, setDataLoaded] = useState(false); // Flag to track if data is loaded

  // Memoized function to generate a hash of the data (securityID, LTP, pChange)
  const generateDataHash = useCallback((data) => {
    return data.map(({ securityID, LTP, pChange }) => `${securityID}-${LTP}-${pChange}`).join(';');
  }, []);

  // Fetch data from the Excel file
  const fetchData = async () => {
    try {
      const response = await fetch('excel/un_performers.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Extract the first three columns
      const filteredData = jsonData.map(({ securityID, LTP, pChange }) => ({ securityID, LTP, pChange }));

      // Compare data hash to detect changes
      const currentDataHash = generateDataHash(filteredData);
      if (currentDataHash !== lastDataHash) {
        setData(filteredData); // Only update the data if the hash has changed
        setLastDataHash(currentDataHash); // Update the last data hash
      }
      setDataLoaded(true); // Set dataLoaded to true after data is fetched
    } catch (error) {
      console.error('Error fetching or processing the Excel file:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount

    // Set up an interval to periodically fetch the data every 10 seconds (or adjust as needed)
    const interval = setInterval(fetchData, 10000); // 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [lastDataHash, generateDataHash]); // Only run this effect when the lastDataHash changes

  return (
    <div>
      <h4>Top 5 Loosing stocks</h4>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Stock Name</th>
            <th>Price</th>
            <th>%Change</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.securityID}</td>
              <td>{row.LTP}</td>
              <td>{row.pChange}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(Toploosing); // Memoize the component to prevent unnecessary re-renders
