import React, { useState, useEffect } from "react";
import { useUserContext } from "../../userContext";
import finance from "../../images/finance.png";
// import AlgoNavbar from "../AlgoStrategy/algoNavbars";
import Footer from "../Footer";
import api from "../api/api";
import Navbars from "../Navbars";

function ViewBroker() {
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState(""); // To show success or error messages
  const { userName, userID } = useUserContext();
  const [brokerId, setBrokerId] = useState("")
  const [mpin, setMpin] = useState("")
  const [password, setPassword] = useState("")
  useEffect(() => {
    // Fetch broker details when the component is mounted
    api.get(`/broker/details/${userID}`)
      .then((res) => {
        const broker = res.data;
        if (broker) {
          setConsumerKey(broker.Consumer_Key);
          setConsumerSecret(broker.Consumer_Secret);
          setMobileNumber(broker.Mobile_Number);
          setBrokerId(broker.Broker_ID);
          setPassword(broker.Password);
          setMpin(broker.mpin)
        }
      })
      .catch((err) => console.log(err));
  }, [userID]);

  const handleConsumerKeyChange = (e) => setConsumerKey(e.target.value);
  const handleConsumerSecretChange = (e) => setConsumerSecret(e.target.value);
  const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);
  const handleMpinChange = (e) => setMpin(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);


  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Prepare data for PUT request
    const updatedData = {
      consumerKey: consumerKey,
      consumerSecret: consumerSecret,
      mobileNumber: mobileNumber,
      mpin:mpin,
      password: password,
      userId: userID
    };

    // Make a PUT request to update broker details
     api.put(`/update/${brokerId}`, updatedData)
      .then((response) => {
        alert("Broker details updated successfully!");
        setMessage("Broker details updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error updating the broker details!", error);
        setMessage("Error updating broker details. Please try again.");
      });
  };

  return (
    <div className="d-flex flex-column">
      <Navbars />
      <div className="mt-5 flex-grow-1">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11 col-lg-11 col-md-10 col-sm-12 col-10 mt-4">
              <div className="card text-black">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 col-xl-5 col-sm-5 order-2 order-lg-1">
                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-0">
                        View Broker
                      </p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={userName}
                              disabled
                            />
                            <label className="form-label" htmlFor="userName">
                              User Name
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={consumerKey}
                              onChange={handleConsumerKeyChange}
                            />
                            <label className="form-label" htmlFor="consumerKey">
                              Consumer Key
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={consumerSecret}
                              onChange={handleConsumerSecretChange}
                            />
                            <label
                              className="form-label"
                              htmlFor="consumerSecret"
                            >
                              Consumer Secret
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="number"
                              className="form-control"
                              value={mobileNumber}
                              onChange={handleMobileNumberChange}
                            />
                            <label
                              className="form-label"
                              htmlFor="mobileNumber"
                            >
                              Mobile Number
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={mpin}
                              onChange={handleMpinChange}
                            />
                            <label
                              className="form-label"
                              htmlFor="mobileNumber"
                            >
                              Mpin
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <label
                              className="form-label"
                              htmlFor="mobileNumber"
                            >
                             Password
                            </label>
                          </div>
                        </div>
                        <div className="text-center">
                          <button type="submit" className="btn btn-primary">
                            Update Details
                          </button>
                        </div>
                      </form>
                      {message && <p className="text-center mt-3">{message}</p>}
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-3 d-flex align-items-center order-1 order-lg-3 justify-content-center">
                      <img
                        src={finance}
                        height={"300rem"}
                        className="img-fluid "
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ViewBroker;
