import React, { useEffect, useState } from "react";
import sipImage from "../../images/Sip.png";
import lumpsumImage from "../../images/Lumpsum.png";
import swpImage from "../../images/Swp.png";
import mfImage from "../../images/MF.png";

function Calculator() {
  const calculators = [
    {
      name: "SIP",
      url: "https://html.algomines.com/sip-calculator.html",
      image: sipImage,
    },
    {
      name: "LumpSum",
      url: "https://html.algomines.com/lumpsum-calculator.html",
      image: lumpsumImage,
    },
    {
      name: "SWP",
      url: "https://html.algomines.com/swp-calculator.html",
      image: swpImage,
    },
    {
      name: "MF",
      url: "https://html.algomines.com/mf-calculator.html",
      image: mfImage,
    },
  ];
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1029);
  const [isHovered, setIsHovered] = useState(false);
const [parthuWidth,setParthuWidth]=useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1029);
      setParthuWidth(window.innerWidth)
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // margin:  isMobile ? "" : "",
        }}
      >
        <h3>Calculators</h3>
        <a
           href="https://html.algomines.com/"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              padding: isMobile ? "5px" : "10px 20px",
              fontSize: isMobile ? "10px" : "16px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              textDecoration: isHovered ? "underline" : "none",
            }}
          >
            See More...
          </button>
        </a>
      </div>

     { parthuWidth>=300&&<div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: isMobile ? "25px" : "5px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {calculators.map((calculator, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              padding: isMobile ? "10px" : "30px", 
              width: isMobile ? "110px" : "200px", 
              height: "180px",
              textAlign: "center",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, box-shadow 0.3s",
              backgroundColor: "#fff",
            }}
            className="calculator-card"
          >
            {calculator.image ? (
              <a rel="noreferrer"  href={calculator.url}>
                <img
                   href={calculator.url}
                   src={calculator.image}
                  alt={calculator.name}
                  style={{
                    maxWidth: isMobile ? "100px" : "170px", 
                    margin: "15px",
                    marginBottom: "10px",
                  }}
                />
              </a>
            ) : null}
          </div>  
        ))}
      </div>}
      {parthuWidth<=300&&<div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
       
        {calculators.map((calculator, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #e0e0e0",
              borderRadius: "12px",
              padding: isMobile ? "10px" : "30px", 
              width: isMobile ? "110px" : "200px", 
              height: "180px",
              textAlign: "center",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, box-shadow 0.3s",
              backgroundColor: "#fff",
            }}
            className="calculator-card"
          >
            {calculator.image ? (
              <a rel="noreferrer" href={calculator.url}>
                <img
                   href={calculator.url}
                   src={calculator.image}
                  alt={calculator.name}
                  style={{
                    maxWidth: isMobile ? "100px" : "170px", 
                    margin: "15px",
                    marginBottom: "10px",
                  }}
                />
              </a>
            ) : null}
          </div>   
        ))}
      </div>}
    </div>
  );
}

export default Calculator;
