import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import * as XLSX from 'xlsx';

const ExcelReadWithoutUplaod = () => {
 /* const [sheetNames, setSheetNames] = useState([]);
  const [data, setData] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('excel/options.xlsx'); // Path to your Excel file
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        // Get sheet names
        const names = workbook.SheetNames;
        setSheetNames(names);
        setSelectedSheet(names[0]); // Set default to the first sheet
        loadSheetData(workbook, names[0]);
      };

      reader.readAsBinaryString(blob);
    };

    fetchData();
  }, []);

  const loadSheetData = (workbook, sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    setData(jsonData);
  };

  const handleSheetChange = (event) => {
    const sheetName = event.target.value;
    setSelectedSheet(sheetName);
    const workbook = XLSX.read(new Blob([data]), { type: 'binary' });
    loadSheetData(workbook, sheetName);
  };

  return (
    <div class="container">
    <div>
      <h2>Options Fetch Data Record</h2>
      <label htmlFor="sheetSelect">Select Stock Name:</label>
      <select id="sheetSelect" value={selectedSheet} onChange={handleSheetChange}>
        {sheetNames.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
      </select>

      {data.length > 0 && (
        <div>
          <h4>Recent Records of Fetch Data: {selectedSheet}</h4>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{data[0][0]}</th>
                <th scope="col">{data[0][1]}</th>
                <th scope="col">{data[0][2]}</th>
                <th scope="col">{data[0][3]}</th>
                <th scope="col">{data[0][4]}</th>
                <th scope="col">{data[0][5]}</th>
              </tr>
            </thead>
            <tbody>
              {data.slice(1, 11).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row[0]}</td>
                  <td>{row[1]}</td>
                  <td>{row[2]}</td>
                  <td>{row[3]}</td>
                  <td>{row[4]}</td>
                  <td>{row[5]}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </div>
  );
};*/
const [sheetNames, setSheetNames] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchExcelFile = async () => {
            try {
                const response = await fetch('excel/options.xlsx'); // Update with your file path
                if (!response.ok) throw new Error('File not found');
                
                const dataBuffer = await response.arrayBuffer();
                const workbook = XLSX.read(new Uint8Array(dataBuffer), { type: 'array' });
                const names = workbook.SheetNames;

                setSheetNames(names);
                setSelectedSheet(names[0]); // Set the first sheet as selected
                
                // Load the first sheet's data immediately
                const firstSheetName = names[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                setData(jsonData);
                setError('');
            } catch (err) {
                setError(`Failed to read the Excel file: ${err.message}`);
            }
        };

        fetchExcelFile();
    }, []);

    const handleSheetChange = (event) => {
        const sheetName = event.target.value;
        setSelectedSheet(sheetName);

        if (sheetName) {
            const fetchData = async () => {
                try {
                    const response = await fetch('excel/options.xlsx'); // Ensure this path is correct
                    const dataBuffer = await response.arrayBuffer();
                    const workbook = XLSX.read(new Uint8Array(dataBuffer), { type: 'array' });
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    setData(jsonData);
                } catch (err) {
                    setError(`Failed to read the data from the sheet: ${err.message}`);
                }
            };
            fetchData();
        } else {
            setData([]); // Clear data if no sheet is selected
        }
    };

    return (
        <div class="container">
          
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div class="container">
                
                <label htmlFor="sheet-select">Select a Stock Name:</label>
                <select id="sheet-select" value={selectedSheet} onChange={handleSheetChange}>
                    <option value="">--Select a sheet--</option>
                    {sheetNames.map((sheet, index) => (
                        <option key={index} value={sheet}>
                            {sheet}
                        </option>
                    ))}
                </select>
            </div>
            {data.length > 0 && (
              <div class="container-sm">
                <h5><span class="badge badge-pill badge-warning">Recent Records of Fetch Data: {selectedSheet}</span></h5>
                <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{data[0][2]}</th>
                <th scope="col">{data[0][4]}</th>
                <th scope="col">{data[0][5]}</th>
                <th scope="col">{data[0][7]}</th>
                <th scope="col">{data[0][13]}</th>
                <th scope="col">{data[0][14]}</th>
                <th scope="col">{data[0][15]}</th>
                <th scope="col">{data[0][16]}</th>
                <th scope="col">{data[0][18]}</th>
              </tr>
            </thead>
            <tbody>
              {data.slice(1, 15).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row[2]}</td>
                  <td>{row[4]}</td>
                  <td>{row[5]}</td>
                  <td>{row[7]}</td>
                  <td>{row[13]}</td>
                  <td>{row[14]}</td>
                  <td>{row[15]}</td>
                  <td>{row[16]}</td>
                  <td>{row[18]}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
            )}
            <h3>Lorem Epsum</h3>
            <p>Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem </p>
        </div>
    );
};




export default ExcelReadWithoutUplaod;
