import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../userContext';
import aboutimage from '../images/loginForm.jpg';
import Navbars from './Navbars';
import Footer from './Footer';
import api from './api/api';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [agree, setAgree] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  // const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { login } = useUserContext(); // Get login method from context

  // Validate form input
  const validateForm = () => {
    setEmailError('');
    setPasswordError('');
    setErrorMessage('');

    let valid = true;

    if (!email || email.length > 100 || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address (max 100 characters).');
      valid = false;
    }

    if (!password || password.length > 100 || !isPasswordStrong(password)) {
      setPasswordError('Password must be between 8-100 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      valid = false;
    }

    // if (!agree) {
    //   setErrorMessage('Please agree to the terms and conditions.');
    //   valid = false;
    // }

    return valid;
  };

  const isPasswordStrong = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;
    return passwordRegex.test(password);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = { email, password };
      setLoading(true); // Set loading to true during the API request

      try {
        // Send login request using axios
        const response = await api.post('/login', formData, {
          headers: { 'Content-Type': 'application/json' },
        });

        setLoading(false); // Set loading to false after request completes
        setApiSuccess('Login successful! Welcome back!');
        setApiError(''); // Reset API error on success

        // Check if response is an array (could be multiple users) or a single object
        if (Array.isArray(response.data)) {
          const userId = response.data.map(user => user.userId);
          const userName = response.data.map(user => user.userName || "Guest"); // Default to "Guest" if userName is null
          console.log('Extracted User IDs:', userName);
          // Use context's login method to store user ID and token
          login(userId[0], response.data[0].token, userName[0]);
          navigate(`/algo-strategies`); // Navigate to the dashboard
        } else {
          const { userId, token, userName } = response.data;
          login(userId, token, userName || "Guest"); // Default to "Guest" if userName is null
          navigate(`/algo-strategies`);
        }
      } catch (error) {
        console.error('Error during login:', error);
        setLoading(false); // Set loading to false after error
        setApiError('Invalid email or password. Please try again.');
        setApiSuccess(''); // Reset success message on error
        // setShowRegisterModal(true); // Show registration modal on error
      }
    }
};


  return (
    <><Navbars /><div className="d-flex flex-column" style={{ minHeight: '100vh' }}>

      <div className=" mt-5 flex-grow-1">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-11 col-xl-10 mt-4">
              <div className="card text-black"> 
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 col-xl-5 col-sm-5 order-2 order-lg-1">
                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-0">Sign In</p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        {apiError && (
                          <div className="alert alert-danger" role="alert">
                            {apiError}
                          </div>
                        )}
                        {apiSuccess && (
                          <div className="alert alert-success" role="alert">
                            {apiSuccess}
                          </div>
                        )}

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="email"
                              id="form3Example3c"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)} />
                            <label className="form-label" htmlFor="form3Example3c">Email</label>
                            {emailError && <div className="text-danger">{emailError}</div>}
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="form3Example4c"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)} />
                            <label className="form-label" htmlFor="form3Example4c">Password</label>
                            {passwordError && <div className="text-danger">{passwordError}</div>}
                          </div>
                        </div>

                        {/* <div className="form-check d-flex justify-content-start mb-3">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value={agree}
                            id="form2Example3c"
                            onChange={() => setAgree(!agree)} />
                          <label className="form-check-label" htmlFor="form2Example3">
                            I agree all statements in <a href="#!">Terms of service</a>
                          </label>
                        </div> */}

                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                            {loading ? 'Logging in...' : 'LogIn'}
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to={'/signup'} className="flex justify-center items-center gap-2 hover:text-[#fda4af] cursor-pointer">
                            <p className="text-[14px]">Create Another Account...</p>
                          </Link>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-3 d-flex align-items-center order-1 order-lg-2 justify-content-center">
                      <img src={aboutimage} className="img-fluid" alt="Sample image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Registration Prompt */}
      {/* {showRegisterModal && (
      <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-modal="true" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Account Registration</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowRegisterModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Your login attempt failed. Do you want to create a new account?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowRegisterModal(false)}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={() => window.location.href = '/signup'}>
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    )} */}

      <Footer />
    </div></>
  );
}

export default Login;
