import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Bar } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SymbolOiCard = () => {
  const [data, setData] = useState([]); // Holds parsed data from the Excel file
  const [displayData, setDisplayData] = useState([]); // Data for displaying charts (Total OI or Change in OI)
  const [isTotalOI, setIsTotalOI] = useState(true); // Flag to toggle between Total OI and Change in OI
  const [isDataLoaded, setIsDataLoaded] = useState(false); // To track if data is loaded
  const [isFileSelected, setIsFileSelected] = useState(false); // Flag to check if a file is selected

  const loadExcelData = (fileName) => {
    fetch(fileName) // Path to your file
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const workbook = XLSX.read(buffer, { type: "buffer" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Use the first sheet
        const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        setData(jsonData);
        setDisplayData(jsonData); // Default to Total OI
        setIsDataLoaded(true); // Mark that data has been loaded
        setIsFileSelected(true); // Set file selected flag to true

        // Automatically show Total Open Interest (OI) by default
        handleTotalOI(jsonData);
      })
      .catch((error) => console.error("Error reading the Excel file:", error));
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1029);

  useEffect(() => {
    // Parse the CSV file and load data

    // Resize event listener to detect screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1029);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Handle Total Open Interest (OI) display
  const handleTotalOI = (data) => {
    const totalOIData = data.map((row) => ({
      symbol: row.Symbol,
      callOI: row.Total_Call_OI,
      putOI: row.Total_Put_OI,
      spotPrice: row.Underlying_Spot_Price, // Add Underlying_Spot_Price
    }));
    setDisplayData(totalOIData);
    setIsTotalOI(true); // Set the flag to Total OI
  };

  // Handle Total Change in Open Interest (OI) display
  const handleChangeInOI = () => {
    const changeInOIData = data.map((row) => ({
      symbol: row.Symbol,
      callDiff: row.Total_Call_OI_Diff,
      putDiff: row.Total_Put_OI_Diff,
      spotPrice: row.Underlying_Spot_Price, // Add Underlying_Spot_Price
    }));
    setDisplayData(changeInOIData);
    setIsTotalOI(false); // Set the flag to Change in OI
  };
  const [activeButton, setActiveButton] = useState("BankNifty");
  const [active, setActive] = useState("toi");
  // Buttons for switching between the files
  const renderButtons = () => (
    <div
      style={{
        padding: "10px",
        margin: "10px",
        backgroundColor: "#af12as",
        borderRadius: "10px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      <button
        onClick={() => {
          setActiveButton("BankNifty");
          loadExcelData("/excel/banknifty_stocks_oi.xlsx");
        }}
        style={{
          borderRadius: "5px",
          backgroundColor: activeButton === "BankNifty" && "#7c84cc",
          color: activeButton === "BankNifty" && "white",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "1px solid gray",
        }}
      >
        BankNifty
      </button>
      <button
        onClick={() => {
          setActiveButton("Nifty50");
          loadExcelData("/excel/nifty50_stocks_oi.xlsx");
        }}
        style={{
          borderRadius: "5px",
          backgroundColor: activeButton === "Nifty50" && "#7c84cc",
          color: activeButton === "Nifty50" && "white",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "1px solid gray",
        }}
      >
        Nifty50
      </button>
      <button
        onClick={() => {
          setActiveButton("Sensex");
          loadExcelData("/excel/sensex_stocks_oi.xlsx");
        }}
        style={{
          borderRadius: "5px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "1px solid gray",
          backgroundColor: activeButton === "Sensex" && "#7c84cc",
          color: activeButton === "Sensex" && "white",
        }}
      >
        Sensex
      </button>
      <button
        // onClick={() => loadExcelData("/excel/bankex_stocks_oi.xlsx")}
        onClick={() => {
          setActiveButton("BankEx");
          loadExcelData("/excel/bankex_stocks_oi.xlsx");
        }}
        style={{
          borderRadius: "5px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "1px solid gray",
          backgroundColor: activeButton === "BankEx" && "#7c84cc",
          color: activeButton === "BankEx" && "white",
        }}
      >
        BankEx
      </button>
      <button
        // onClick={() => loadExcelData("/excel/finnifty_stocks_oi.xlsx")}
        onClick={() => {
          setActiveButton("Finnifty");
          loadExcelData("/excel/finnifty_stocks_oi.xlsx");
        }}
        style={{
          borderRadius: "5px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          border: "1px solid gray",
          backgroundColor: activeButton === "Finnifty" && "#7c84cc",
          color: activeButton === "Finnifty" && "white",
        }}
      >
        Finnifty
      </button>
    </div>
  );
  useEffect(() => {
    loadExcelData("/excel/banknifty_stocks_oi.xlsx");
  }, []);
  return (
    <div>
      {/* Display file selection buttons (these will stay visible) */}
      {renderButtons()}

      {/* Show Total OI and Change in OI buttons only if data is loaded */}
      {isFileSelected && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              margin: "5px",
            }}
          >
            <button
              onClick={() => {
                handleTotalOI(data);
                setActive("toi");
              }}
              style={{
                borderBottom: active === "toi" && "1px solid green",
                borderRight: active === "toi" && "2px solid gray",
                // margin:"5px",
                backgroundColor: active === "toi" && "#09ba09",
                color: active === "toi" && "white",
                borderBottomRightRadius: active === "toi" && "10px",
              }}
            >
              Total Open Interest (OI)
            </button>
            <button
              style={{
                borderBottom: active === "coi" && "1px solid green",
                borderRight: active === "coi" && "2px solid gray",
                // margin:"5px",
                minWidth: "100px",
                backgroundColor: active === "coi" && "#09ba09",
                color: active === "coi" && "white",
                borderBottomRightRadius: active === "coi" && "10px",
              }}
              onClick={() => {
                handleChangeInOI();
                setActive("coi");
              }}
            >
              Total Change in OI
            </button>
          </div>
        </div>
      )}

      {/* Display the cards only after data is loaded */}
      {isFileSelected &&
        isDataLoaded &&
        displayData.length > 0 &&
        (isMobile ? (
          <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          style={{ zIndex: "0" }}
          breakpoints={{
            1029: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            763: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            333: {
              slidesPerView: 1, // Display only 1 slide for screen width of 338px or below
              // spaceBetween: 10,
            },
          }}
        >
          {displayData.map((stock) => (
            <SwiperSlide key={stock.symbol}>
              <div style={style.card}>
                <h6 style={{ textAlign: "center", color: "black", fontSize: "1rem", margin: "0px", padding: "0px" }}>
                  {stock.symbol} : &#8377;{stock.spotPrice}
                </h6>
        
                <div>
                  <Bar
                    data={{
                      labels: ["Call OI", "Put OI"],
                      datasets: [
                        {
                          label: isTotalOI ? "Call OI" : "Call OI Diff",
                          data: isTotalOI
                            ? [stock.callOI, stock.putOI]
                            : [stock.callDiff, stock.putDiff],
                          backgroundColor: [
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 159, 64, 1)",
                          ],
                          borderWidth: 0,
                        },
                      ],
                    }}
                    options={{
                      indexAxis: "y", // Horizontal bars
                      responsive: true,
                      plugins: {
                        tooltip: {
                          enabled: true,
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          display: false, // Hide X-Axis
                          grid: {
                            display: false, // Hide grid lines on X-Axis
                          },
                        },
                        y: {
                          display: true, // Display Y-Axis (symbols)
                          grid: {
                            display: false, // Hide grid lines on Y-Axis
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        ) : (
          <div style={styles.desktopContainer}>
            <div style={styles.desktopContainer}>
              {displayData.map((stock) => (
                <div key={stock.symbol} style={styles.card}>
                  <h6
                    className=""
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontSize: "0.8rem",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {stock.symbol} : &#8377;{stock.spotPrice}
                  </h6>

                  <div>
                    <Bar
                      data={{
                        labels: ["Call OI", "Put OI"],
                        datasets: [
                          {
                            label: isTotalOI ? "Call OI" : "Call OI Diff",
                            data: isTotalOI
                              ? [stock.callOI, stock.putOI]
                              : [stock.callDiff, stock.putDiff],
                            backgroundColor: [
                              "rgba(54, 162, 235, 1)",
                              "rgba(255, 159, 64, 1)",
                            ],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={{
                        indexAxis: "y", // Horizontal bars
                        responsive: true,
                        plugins: {
                          tooltip: {
                            enabled: true,
                          },
                          legend: {
                            display: false, // Hide legend if needed
                          },
                        },
                        scales: {
                          x: {
                            display: false, // Hide X-Axis
                            grid: {
                              display: false, // Hide grid lines on X-Axis
                            },
                          },
                          y: {
                            display: true, // Display Y-Axis (symbols)
                            grid: {
                              display: false, // Hide grid lines on Y-Axis
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

      {/* Show loading state if data is not loaded yet */}
      {isFileSelected && !isDataLoaded && <p>Loading data...</p>}
    </div>
  );
};

export default SymbolOiCard;
const styles = {
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    flexWrap: "wrap",
  },
  button: {
    margin: "0 5px",
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s, color 0.3s",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "20px",
  },
  desktopContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center", // Align all cards to the left
    padding: "20px",
  },
  card: {
    width: "165px", // Adjusted for smaller size on desktop
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    margin: "10px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    fontSize: "10px",
  },
  info: {
    marginBottom: "-15px",
  },
  infoItem: {
    marginBottom: "5px",
    fontSize: "12px", // Adjusted for desktop
    fontWeight: "bold",
  },
  chart: {
    height: "90px",
    marginBottom: "-20px",
  },
};

const style = {
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    flexWrap: "wrap",
  },
  button: {
    margin: "0 5px",
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.3s, color 0.3s",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "0px",
  },

  card: {
    width: "155px", // Adjusted for smaller size on desktop
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    margin: "10px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    fontSize: "10px",
  },
  info: {
    marginBottom: "-15px",
  },
  infoItem: {
    marginBottom: "5px",
    fontSize: "12px", // Adjusted for desktop
    fontWeight: "bold",
  },
  chart: {
    height: "90px",
    marginBottom: "-20px",
  },
};
