import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

// Add icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram
);

function Footer() {
  return (
    <div className="text-center bg-body-tertiary">
      <div className="text-center">
        <span>© 2025</span>
        <span className="mx-2">
          <Link className="text-body" to="/">
            algomines.com
          </Link>
        </span>
      </div>

      <div className='"text-center'>
        <Link
          data-mdb-ripple-init
          className="btn btn-link btn-floating btn-lg text-body m-1"
          to="#!"
          role="button"
          data-mdb-ripple-color="dark"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </Link>

        <Link
          data-mdb-ripple-init
          className="btn btn-link btn-floating btn-lg text-body m-1"
          to="#!"
          role="button"
          data-mdb-ripple-color="dark"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </Link>

        <Link
          data-mdb-ripple-init
          className="btn btn-link btn-floating btn-lg text-body m-1"
          to="#!"
          role="button"
          data-mdb-ripple-color="dark"
        >
          <FontAwesomeIcon icon={faGoogle} />
        </Link>

        <Link
          data-mdb-ripple-init
          className="btn btn-link btn-floating btn-lg text-body m-1"
          to="#!"
          role="button"
          data-mdb-ripple-color="dark"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
      </div>
    </div>
  );
}

export default Footer;
