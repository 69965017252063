import React from 'react';
import './Dashboard.css';
import Navbars from './Navbars';

import Dashboard1 from './dashboard1';
import Footer from './Footer';

const Dashboard = () => {

  return (
    <>
    <div className="dashboard">
      <Navbars />
     <Dashboard1/>
    
    </div>
     <Footer/>
    </>
    
  );
};

export default React.memo(Dashboard);
