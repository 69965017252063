import React from 'react';
import Navbars from './Navbars';
import Footer from './Footer';
import background2 from '../images/background2.png';
import './header.css';
import TopGainers from './header/TopGainers'
import TopLosers from './header/TopLosers'
import Calculator from './header/calculator';
import SymbolCards from './header/SymbolCards';
import {Link} from 'react-router-dom'

function Header() {
  return (
    <>
      <div id="mains">
        <Navbars />

        <div className="rows">
          <div className="col-md">
            <div className='names'>
              <h1>Experience <span>the future of trading</span> with our advanced algorithms.</h1>
              <p className='detail'>Unlock the power of data-driven insights with real-time Open Interest (OI) analysis and make faster decisions</p>

              <div className="button-group">
                <Link to="/Dashboard"><button><h6 className='text-white'>OI Data</h6></button></Link>
                <Link to="/stocktables"><button><h6 className='text-white'>Market Data</h6></button> </Link>
                <Link to="/algo-strategy"><button><h6 className='text-white'>Algo Templates</h6></button></Link>
                <Link target="_blank" to="https://html.algomines.com/mfbacktest.html"><button><h6 className='text-white'>MF Backtest</h6></button></Link>
              </div>
            </div>
          </div>

          <div className="col-md">
            <img src={background2} className="image" alt="" />
          </div>
        </div>
         <SymbolCards/>
         <TopGainers/>
         <TopLosers/>
         <Calculator/>
      </div>
      <Footer />
    </>
  )
}

export default Header;