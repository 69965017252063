import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import * as XLSX from 'xlsx';
import Badge from 'react-bootstrap/Badge';
   


const Topgainers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('excel/top_performers.xlsx');
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Extract the first three columns
      const filteredData = jsonData.map(({ securityID, LTP, pChange }) => ({ securityID, LTP, pChange }));
      setData(filteredData);
    };

    fetchData();
  }, []);

  return (
    <div>
      <h4>Top 5 Gaining stocks</h4>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <th >Stock Name</th>
            <th >Price</th>
            <th >%Change</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td >{row.securityID}</td>
              <td >{row.LTP}</td>
              <td >{row.pChange}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Topgainers
