import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";

const TopLosers = () => {
  const [stocks, setStocks] = useState([]);
  const [selectedFile, setSelectedFile] = useState("excel/Top_100_Losers.xlsx");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1029);

  const fetchExcelData = async (fileName) => {
    try {
      // Fetch the Excel file (prevent caching with a timestamp)
      const response = await fetch(
        `/${fileName}?timestamp=${new Date().getTime()}`
      );
      const data = await response.arrayBuffer();

      // Parse the Excel file
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet name
      const sheet = workbook.Sheets[sheetName]; // Get the first sheet
      const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert sheet to JSON

      // Sort by PercentChange and take the top 5
      const topStocks = jsonData
        .sort((a, b) => b.PercentChange - a.PercentChange) // Sort descending by PercentChange
        .slice(0, 5); // Take top 5 stocks

      setStocks(topStocks); // Update state with top stocks
    } catch (error) {
      console.error(
        `Error fetching or processing Excel file (${fileName}):`,
        error
      );
    }
  };

  useEffect(() => {
    fetchExcelData(selectedFile); // Fetch data for the selected file

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1029);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedFile]); // Re-fetch data when selectedFile changes

  return (
    <div style={{ marginTop: 30 }}>
      <h3>Top 5 loss Stocks</h3>

      {/* File selection menu */}
      <div style={{ marginLeft: "3rem" }}>
        <button
          style={{
            margin: "5px",
            padding: "10px",
            borderRadius: "8px",
            border:
              selectedFile === "excel/Top_100_Losers.xlsx"
                ? "2px solid blue"
                : "1px solid gray",
            backgroundColor:
              selectedFile === "excel/Top_100_Losers.xlsx"
                ? "#f0f8ff"
                : "white",
            cursor: "pointer",
          }}
          onClick={() => setSelectedFile("excel/Top_100_Losers.xlsx")}
        >
          Large
        </button>
        <button
          style={{
            margin: "5px",
            padding: "10px",
            borderRadius: "8px",
            border:
              selectedFile === "excel/Next_150_Losers.xlsx"
                ? "2px solid blue"
                : "1px solid gray",
            backgroundColor:
              selectedFile === "excel/Next_150_Losers.xlsx"
                ? "#f0f8ff"
                : "white",
            cursor: "pointer",
          }}
          onClick={() => setSelectedFile("excel/Next_150_Losers.xlsx")}
        >
          Mid
        </button>
        <button
          style={{
            margin: "5px",
            padding: "10px",
            borderRadius: "8px",
            border:
              selectedFile === "excel/small_cap_Losers.xlsx"
                ? "2px solid blue"
                : "1px solid gray",
            backgroundColor:
              selectedFile === "excel/small_cap_Losers.xlsx"
                ? "#f0f8ff"
                : "white",
            cursor: "pointer",
          }}
          onClick={() => setSelectedFile("excel/small_cap_Losers.xlsx")}
        >
          Small
        </button>
      </div>

      {isMobile ? (
        // Render Swiper on mobile
        <Swiper
          spaceBetween={10}
          centeredSlides={true}
          slidesPerView={3}
          style={{ zIndex: "0" }}
          // breakpoints={{
          //   1029: {
          //     slidesPerView: 3, 
          //   },
          //   682: {
          //     slidesPerView: 3, 
          //   },
          //   350: {
          //     slidesPerView: 1,
          //   },
          // }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        
        >
          {stocks.map((stock, index) => (
            <SwiperSlide key={index}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "8px",
                    width: "160px",
                    height: "100px",
                    textAlign: "center",
                    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    overflow: "hidden",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "12px",
                      margin: "0 0 5px",
                      fontWeight: "bold",
                    }}
                  >
                    {stock.Symbol}
                  </h3>
                  <p
                    style={{
                      margin: "2px 0",
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                  >
                    ₹{stock.currentPrice}
                  </p>
                  <p style={{ margin: "2px 0", fontSize: "10px" }}>
                    Prev: ₹{stock.previousClose}
                  </p>
                  <p
                    style={{
                      margin: "2px 0",
                      color: stock.PercentChange >= 0 ? "green" : "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {stock.PercentChange}%
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        // Render Desktop Layout (non-Swiper) for larger screens
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          {stocks.map((stock, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "8px",
                width: "160px",
                height: "100px",
                textAlign: "center",
                boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "10px",
                overflow: "hidden",
              }}
            >
              <h3
                style={{
                  fontSize: "12px",
                  margin: "0 0 5px",
                  fontWeight: "bold",
                }}
              >
                {stock.Symbol}
              </h3>
              <p
                style={{
                  margin: "2px 0",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                ₹{stock.currentPrice}
              </p>
              <p style={{ margin: "2px 0", fontSize: "10px" }}>
                Prev: ₹{stock.previousClose}
              </p>
              <p
                style={{
                  margin: "2px 0",
                  color: stock.PercentChange >= 0 ? "green" : "red",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {stock.PercentChange}%
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopLosers;
