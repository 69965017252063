import React from 'react';
import Listallstocks from './Listallstocks';
import Topgainers from './Topgainers';
import Toploosing from './Toploosing';
import Navbars from './Navbars';
import Footer from './Footer';
import "./stockTables.css";

function StockTables() {
  return (
    <>
     <div id="bnr" className="stock">
      <Navbars />
      <div className='table-list-stock'>

      <h5 className="marketData"> Market Data: List By Performance</h5>
      <div className="row">
        <div className="col-xl-8 col-md-12 col-sm-12 stocks-cards">
          <div className="cards">
            <Listallstocks />
          </div>
        </div>
        <div className="col-xl-4 col-md-12 col-sm-12">
          <div className="cards mb-3">
            <Topgainers />
          </div>
          <div className="cards">
            <Toploosing />
          </div>
        </div>
      </div>
      </div>

     
    </div>
    <Footer />
    </>
   
  );
}

export default StockTables;
