import { Route, Routes } from 'react-router-dom';

import Dashboard from './Components/Dashboard';
import ExcelReadWithoutUplaod from './Components/ExcelReadWithoutUplaod';
import Topgainers from './Components/Topgainers';
import Toploosing from './Components/Toploosing';
import Listallstocks from './Components/Listallstocks';
import StockTables from './Components/StockTables';
import OffcanvasExample from './Components/OffcanvasExample';
import Header from './Components/Header';
import About from './Components/About';
import Exceltohtmlwithfilter from './Components/Exceltohtmlwithfilter';
import Hotstocks from './Components/Hotstocks';

import React from 'react';
import Login from './Components/Login';
import SelectStrategy from './Components/AlgoStrategy/selectStrategy';
import { UserProvider } from "./userContext";
import SelectStrategyData from './Components/algoStrategyData/selectStrategyData';
import LogIn from './Components/algoStrategyData/About';
import AddBroker from './Components/algoStrategyData/addBroker';
import ViewBroker from './Components/algoStrategyData/viewBroker';
import AlgoNavbar from './Components/AlgoStrategy/algoNavbars';

function App() {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="ExcelReadWithoutUplaod" element={<ExcelReadWithoutUplaod />} />
        <Route path="topgainers" element={<Topgainers />} />
        <Route path="toploosing" element={<Toploosing />} />
        <Route path="listallstocks" element={<Listallstocks />} />
        <Route path="stocktables" element={<StockTables />} />
        <Route path="offcanvas" element={<OffcanvasExample />} />
        <Route path="signup" element={<About />} />
        <Route path="Signin" element={<Login />} />
        <Route path="algo-strategies" element={<SelectStrategy />} />
        <Route path="algo-strategy" element={<SelectStrategyData />} />
        <Route path="hotstocks" element={<Hotstocks />} />
        <Route path="Exceltohtmlwithfilter" element={<Exceltohtmlwithfilter />} />
        <Route path="Login" element={<LogIn />} />
        <Route path="algo-strategy/add-broker" element={<AddBroker />} />
        <Route path="algo-strategy/view-broker" element={<ViewBroker />} />
        {/* <Route path="/" element={<AlgoNavbar />} /> */}
      </Routes>
    </UserProvider>
  );
}

export default App;
