import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Listallstocks = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("excel/bse_stock_data_results.xlsx");
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Extract the first three columns
      const filteredData = jsonData.map(
        ({ stockName, currentPrice, previousClose }) => ({
          stockName,
          currentPrice,
          previousClose,
        })
      );
      setData(filteredData);
    };

    fetchData();
  }, []);

  const itemsPerPage = 9;

  // Filter the data based on search term
  const filteredData = data.filter((row) =>
    row.stockName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData?.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Reset to page 1 whenever search term changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);  // Reset the page to 1 whenever the search term changes
  };

  return (
    <div>
      <Row className="align-items-start" style={{ marginBottom: "18px" }}>
        <Col xs={6}>
          <h4>List of Stocks</h4>
        </Col>
        <Col xs={4}>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}  // Use the new search handler
          />
        </Col>
      </Row>
      <Table striped bordered hover size="md">
        <thead>
          <tr>
            <th>Stock Name</th>
            <th>Price</th>
            <th>%Change</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.stockName}</td>
              <td>{row.currentPrice}</td>
              <td>
                {Math.round(
                  ((row.currentPrice * 100) / row.previousClose) * 100
                ) / 100}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <div
        className="flex mt-6 flex-wrap"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <p className="text-gray-600" style={{ color: "#555", fontSize: "14px" }}>
          {startIndex + 1}-{endIndex} of {filteredData.length} stocks
        </p>
        <div className="flex gap-4" style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-3 py-1 border rounded-lg text-gray-700"
            style={{
              padding: "8px 16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
              cursor: currentPage === 1 ? "not-allowed" : "pointer",
              color: "#007bff",
              fontWeight: "bold",
            }}
          >
            Prev
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border rounded-lg text-gray-700"
            style={{
              padding: "8px 16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              backgroundColor: "#f8f9fa",
              cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              color: "#007bff",
              fontWeight: "bold",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};


export default Listallstocks;
