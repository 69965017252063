import React, { useState, useEffect } from 'react';
import Footer from '../Footer';
import Navbars from '../Navbars';
import { Link } from 'react-router-dom';
import "./strategy.css"
import api from '../api/api';

const SelectStrategyData = () => {
  // State to hold the strategies data
  const [strategies, setStrategies] = useState([]);
  // State for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStrategy = async () => {
      try {
        const response = await api.get("/select-strategy");
        setStrategies(response.data); // Store data in state
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        setError(error.message); // Set error if there's an issue
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchStrategy();
  }, []);

  var a = 1;

  return (
    <div className="d-flex">
      <Navbars />
      <div>
        <h3 className='text-center'>Select Strategy</h3>

      </div>
      <div className='main-sta-card'>

        <div className='' style={
          {
            // margin:"auto"
          }
        }>
          {strategies.map((strategy) => <div key={strategy.Strategy_ID} className='strategy-card'>

            <div>
              <h2 className='text-center'>
                {strategy.Name}
              </h2>
              <p align='center'>
                Info: For Nutral <br></br>(sideways) market
              </p>
            </div>
            {strategy.Name === "STRADDLE" && <div>
              <h2 className='text-center'>
                POSITIONS
              </h2>
              <p align='center'> 
                1. CE ATM sell<br></br>
                2. PE ATM SELL
              
              </p>
            
            </div>}{strategy.Name !== "STRADDLE" && <div>
              <h2 className='text-center'>
                POSITIONS
              </h2>
              <p align='center'> 
                1. CE OTM2 sell<br></br>
                2. PE OTM2 SELL
              
              </p>
            </div>}
            <div  align='center'>
              <Link to="/Login"  style={{marginTop:"4rem"}} className="btn btn-success sm:text-xs" 
            
              >
                
                  Subscribe
              </Link>

            </div>

          </div >)}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelectStrategyData;
