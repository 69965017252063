import React, { useState } from "react";
import { useUserContext } from "../../userContext";
import bullmarket from "../../images/bullmarket.png";
// import AlgoNavbar from "../AlgoStrategy/algoNavbars";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import Navbars from "../Navbars";

function AddBroker() {
  const [password, setPassword] = useState("");
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mpin, setMpin] = useState("");
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userName, userID } = useUserContext(); // Get login method from context
  // Validate form input

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      password,
      consumerKey,
      consumerSecret,
      mobileNumber,
      mpin,
      userId: userID,
    };
    setLoading(true); // Show loading indicator

    try {
      // Make API call to add broker
      const response = await api.post(
        "/create/broker",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Success response
      if (response.status === 200) {
        setApiSuccess("Broker added successfully!");
        setLoading(false); // Hide loading indicator
        // Optionally, reset the form fields here
        setPassword("");
        setConsumerKey("");
        setConsumerSecret("");
        setMobileNumber("");
        setMpin("");
        alert("Broker added successfully!");
        navigate("/algo-strategies");
      } else {
        setApiError("Unexpected response from server. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      // Error handling
      if (error.response) {
        // API responded with a status other than 2xx
        setApiError(
          `Error: ${error.response.data.message || "Please try again later."}`
        );
      } else if (error.request) {
        // No response was received from the server
        setApiError(
          "Server did not respond. Please check your network connection."
        );
      } else {
        // Something else went wrong
        setApiError("There was an error with your request. Please try again.");
      }
      setLoading(false); // Hide loading indicator
    }
  };

  return (
    <div className="d-flex flex-column">
      <Navbars />
      <div className="mt-5 flex-grow-1">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11 col-lg-11 col-md-10 col-sm-12 col-10 mt-4">
              <div className="card text-black">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 col-xl-5 col-sm-5 order-2 order-lg-1">
                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-0">
                        Add Broker
                      </p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        {apiError && (
                          <div className="alert alert-danger" role="alert">
                            {apiError}
                          </div>
                        )}
                        {apiSuccess && (
                          <div className="alert alert-success" role="alert">
                            {apiSuccess}
                          </div>
                        )}

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={userName}
                              disabled
                            />
                            <label className="form-label" htmlFor="userName">
                              User Name
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={consumerKey}
                              onChange={(e) => setConsumerKey(e.target.value)}
                            />
                            <label className="form-label" htmlFor="consumerKey">
                              Consumer Key
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={consumerSecret}
                              onChange={(e) =>
                                setConsumerSecret(e.target.value)
                              }
                            />
                            <label
                              className="form-label"
                              htmlFor="consumerSecret"
                            >
                              Consumer Secret
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-phone fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                            <label
                              className="form-label"
                              htmlFor="mobileNumber"
                            >
                              Mobile Number
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              className="form-control"
                              value={mpin}
                              onChange={(e) => setMpin(e.target.value)}
                            />
                            <label className="form-label" htmlFor="mpin">
                              Mpin
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg"
                            disabled={loading}
                          >
                            {loading ? "Adding Broker..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-5 col-sm-3 d-flex align-items-center order-1 order-lg-3 justify-content-center">
                      <img
                        src={bullmarket}
                        height={"300rem"}
                        className="img-fluid "
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddBroker;
