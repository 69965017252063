import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [userID, setUserID] = useState(localStorage.getItem('userID') || null);
  const [userName, setUserName] = useState(localStorage.getItem('userName') || "Guest"); // Default to "Guest" if null
  const [brokerId, setBrokerId] = useState(localStorage.getItem('brokerId') || null); // New state for brokerId
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken'));

  useEffect(() => {
    // Check if user is logged in from localStorage on initial load
    const token = localStorage.getItem('authToken');
    const storedUserID = localStorage.getItem('userID');
    const storedUserName = localStorage.getItem('userName');
    const storedBrokerId = localStorage.getItem('brokerId');
    
    // If token and user info exist, log the user in
    if (token && storedUserID && storedUserName) {
      setIsLoggedIn(true);
      setUserID(storedUserID);
      setUserName(storedUserName);
      setBrokerId(storedBrokerId); // Set brokerId if available
    } else {
      setIsLoggedIn(false);
      setUserID(null);
      setUserName("Guest"); // Default to "Guest" if no userName in localStorage
      setBrokerId(null); // Reset brokerId if not found
    }
  }, []);

  const login = (userId, token, userName, brokerId) => {
    const name = userName || "Guest"; // Default to "Guest" if userName is null or undefined
    localStorage.setItem('authToken', token);
    localStorage.setItem('userID', userId);
    localStorage.setItem('userName', userName); // Store userName in localStorage
    localStorage.setItem('brokerId', brokerId); // Store brokerId in localStorage
    setIsLoggedIn(true);
    setUserID(userId);
    setUserName(userName); // Ensure userName is set
    setBrokerId(brokerId); // Set brokerId
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userID');
    localStorage.removeItem('userName');
    localStorage.removeItem('brokerId'); // Remove brokerId from localStorage
    setIsLoggedIn(false);
    setUserID(null);
    setUserName("Guest"); // Reset to default value
    setBrokerId(null); // Reset brokerId
  };

  return (
    <UserContext.Provider value={{ userID, userName, brokerId, isLoggedIn, setIsLoggedIn, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
