import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../userContext"; // Use the UserContext
import logo from "../images/logo.png";
import menu from "../images/menu.png";
import api from "./api/api";

const Navbars = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { userName, isLoggedIn, logout, setIsLoggedIn, userID } = useUserContext(); // Access context

  const [nav, setNav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
        setNav(true);
    } else {
        setNav(false);
    }
};

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownVisible(!dropdownVisible);
  };

  const handleSignOut = () => {
    logout(); // Calls logout function from context
    setIsLoggedIn(false); // Manually set logged-out state if needed
  };

  const handle = () => {
    window.location.href = "/algo-strategy/add-broker";
  };

  const handleView = () => {
    window.location.href = "/algo-strategy/view-broker";
  };

  useEffect(() => { 

    window.addEventListener('scroll', changeBackground);
    return () => {
        window.removeEventListener('scroll', changeBackground);  
    };
    
   
}, []);

  const [error, setError] = useState("");
 
  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await api.get(`/broker/user/${userID}`);
        } catch (err) {
            if (err.response) {
                  setError(err.response.data.msgCode)
               
            } else if (err.request) {
                console.log('Error request:', err.request);
            } else {
                console.log('Error message:', err.message);
            }
        }
    };

    if (userID) {
        fetchUserData();
    }
}, [userID]);

  return (
    <nav className={nav ? 'nav active' : 'nav'}>
      <div className="container">
        <Link className="logo" to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="menu-icon" onClick={handleShowNavbar}>
          {" "}
          <img src={menu} alt="Menu Icon" width={50} />
        </div>

        <div className={`navs-elements ${showNavbar ? "active" : ""}`}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {isLoggedIn ? (
              <li>
                <Link to="/algo-strategies">Algo Strategies</Link>
              </li>
            ) : (
              <li>
                <Link to="/algo-strategy">Algo Strategies</Link>
              </li>
            )}

            <li>
              <Link to="/stocktables">Market Data</Link>
            </li>
            {/* <li>
              <a href="/fd-calculator/index.html">Calculators</a>
            </li> */}
            {isLoggedIn ? (
              <>
                <li className="text-black" onClick={toggleDropdown}>
                  <Link to="#">
                    User: {userName} <span>&#9662;</span>
                  </Link>
                  <ul
                    className={`dropdown ${dropdownVisible ? "visible" : ""}`}
                  >
                     {error === "AddBroker" ? (
                      <li>
                        <Link to="/algo-strategy/add-broker" onClick={handle}>
                          Add Broker
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link
                          to="/algo-strategy/view-broker"
                          onClick={handleView}
                        >
                          View Broker
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/" onClick={handleSignOut}>
                        Sign Out
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <li>
                <Link to="/signin">Sign In</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbars;
