import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Dashboard.css";

import SymbolOiCard from "./stockoicard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Dashboard1 = () => {
  const [data, setData] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [underlyingSpotPrice, setUnderlyingSpotPrice] = useState(0);
  const [filterRange, setFilterRange] = useState(20);
  const [optionChainData, setOptionChainData] = useState([]);
  const [selectedOptionChain, setSelectedOptionChain] = useState("");
  const [isOptionChainVisible, setIsOptionChainVisible] = useState(false); // To toggle visibility of option chain data
  // Load CSV and Parse Data
  useEffect(() => {
    fetch("excel/option_chain_data_all_instrument.csv") // Replace with your file path
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const parsedData = result.data;
            setData(parsedData);

            // Extract unique symbols
            const uniqueSymbols = [
              ...new Set(parsedData.map((row) => row.Symbol)),
            ];
            setSymbols(uniqueSymbols);

            if (uniqueSymbols.length > 0) {
              setSelectedSymbol(uniqueSymbols[0]);
            }
          },
        });
      });
  }, []);

  // Update Expiry Dates Based on Selected Symbol
  useEffect(() => {
    if (selectedSymbol) {
      const filteredExpiry = [
        ...new Set(
          data
            .filter((row) => row.Symbol === selectedSymbol)
            .map((row) => row["Expiry Date"])
        ),
      ];
      setExpiryDates(filteredExpiry);

      if (filteredExpiry.length > 0) {
        setSelectedExpiry(filteredExpiry[0]);
      }
    }
  }, [selectedSymbol, data]);

  // Filter Data Based on Selected Symbol and Expiry Date
  useEffect(() => {
    if (selectedSymbol && selectedExpiry) {
      const filtered = data.filter(
        (row) =>
          row.Symbol === selectedSymbol && row["Expiry Date"] === selectedExpiry
      );

      // Get `underlying_spot_price` from the first row
      const spotPrice = parseFloat(filtered[0]?.underlying_spot_price || 0);
      setUnderlyingSpotPrice(spotPrice);

      // Find the closest strike price
      const sortedData = filtered.sort(
        (a, b) =>
          Math.abs(parseFloat(a.strike_price) - spotPrice) -
          Math.abs(parseFloat(b.strike_price) - spotPrice)
      );

      const closestIndex = filtered.findIndex(
        (row) =>
          parseFloat(row.strike_price) ===
          parseFloat(sortedData[0].strike_price)
      );

      // Extract filtered range of data based on the filter range
      const start = Math.max(closestIndex - filterRange, 0);
      const end = Math.min(closestIndex + filterRange, filtered.length);
      const filteredRange = filtered.slice(start, end);

      // Sort the data in ascending order based on strike_price
      const sortedFilteredData = filteredRange.sort(
        (a, b) => parseFloat(a.strike_price) - parseFloat(b.strike_price)
      );

      setFilteredData(sortedFilteredData);

      // Option Chain Data based on the selected symbol and expiry
      const optionChainForSelectedExpiry = filtered.map((row) => ({
        strike_price: row.strike_price,
        call_oi: row.call_oi,
        put_oi: row.put_oi,
        call_oi_diff: row.call_oi_diff,
        put_oi_diff: row.put_oi_diff,
      }));
      setOptionChainData(optionChainForSelectedExpiry);
    }
  }, [selectedSymbol, selectedExpiry, data, filterRange]);

  // Calculate sum of call_oi and put_oi for filtered expiry date data (without filter range)
  const totalCallOI = data
    .filter(
      (row) =>
        row.Symbol === selectedSymbol && row["Expiry Date"] === selectedExpiry
    )
    .reduce((sum, row) => sum + (parseInt(row.call_oi) || 0), 0);
  const totalPutOI = data
    .filter(
      (row) =>
        row.Symbol === selectedSymbol && row["Expiry Date"] === selectedExpiry
    )
    .reduce((sum, row) => sum + (parseInt(row.put_oi) || 0), 0);

  // Calculate sum of call_oi_diff and put_oi_diff for filtered expiry date data (without filter range)
  const totalCallOIDiff = data
    .filter(
      (row) =>
        row.Symbol === selectedSymbol && row["Expiry Date"] === selectedExpiry
    )
    .reduce((sum, row) => sum + (parseInt(row.call_oi_diff) || 0), 0);
  const totalPutOIDiff = data
    .filter(
      (row) =>
        row.Symbol === selectedSymbol && row["Expiry Date"] === selectedExpiry
    )
    .reduce((sum, row) => sum + (parseInt(row.put_oi_diff) || 0), 0);

  const maxValue = Math.max(
    ...filteredData.map((d) =>
      Math.max(parseFloat(d.call_oi || 0), parseFloat(d.put_oi || 0))
    ),
    ...filteredData.map((d) =>
      Math.max(parseFloat(d.call_oi_diff || 0), parseFloat(d.put_oi_diff || 0))
    )
  );

  const minValue = Math.min(
    ...filteredData.map((d) =>
      Math.min(parseFloat(d.call_oi || 0), parseFloat(d.put_oi || 0))
    ),
    ...filteredData.map((d) =>
      Math.min(parseFloat(d.call_oi_diff || 0), parseFloat(d.put_oi_diff || 0))
    )
  );

  // Max and Min values for dynamic scaling
  const maxValueOIDiff = Math.max(
    ...filteredData.map((d) =>
      Math.max(parseFloat(d.call_oi_diff || 0), parseFloat(d.put_oi_diff || 0))
    )
  );

  const minValueOIDiff = Math.min(
    ...filteredData.map((d) =>
      Math.min(parseFloat(d.call_oi_diff || 0), parseFloat(d.put_oi_diff || 0))
    )
  );

  // Responsive Y-Axis Calculation based on chart height
  const yAxisDomain = [
    minValueOIDiff <= 0 ? minValueOIDiff : 0,
    maxValueOIDiff * 1.2 || 1e6, // 1.2 multiplier to give some padding to the top
  ];

  const [hoveredBar, setHoveredBar] = useState(null);

  const handleMouseEnter = (dataKey) => {
    setHoveredBar(dataKey);
  };

  const handleMouseLeave = () => {
    setHoveredBar(null);
  };

  const [hovered, setHovered] = useState(null);

  const handleMouseEnterr = (dataKey) => {
    setHovered(dataKey);
  };

  const handleMouseLeavee = () => {
    setHovered(null);
  };
  const toggleOptionChainVisibility = () => {
    setIsOptionChainVisible(!isOptionChainVisible);
  };

  return (
    <>
      <h5>Open Interest Analysis</h5>
      <div className="filters-container">
        <div className="filter-card unified-card">
          <div className="filter-row">
            <label>
              Symbol:
              <select
                value={selectedSymbol}
                onChange={(e) => setSelectedSymbol(e.target.value)}
              >
                {symbols.map((symbol) => (
                  <option key={symbol} value={symbol}>
                    {symbol}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Expiry Date:
              <select
                value={selectedExpiry}
                onChange={(e) => setSelectedExpiry(e.target.value)}
              >
                {expiryDates.map((date) => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="filter-row">
            <label>
              Filter Range:
              <select
                value={filterRange}
                onChange={(e) => setFilterRange(Number(e.target.value))}
              >
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={60}>60</option>
              </select>
            </label>

            <div className="spot-price">
              <label>Spot Price:</label>
              <span className="spot">{underlyingSpotPrice}</span>
            </div>
          </div>
        </div>
      </div>{" "}
      <h5>Open Interest (OI) and Total OI</h5>
      <div className="chart-container">
        <div className="chart large">
          <h6>Open Interest (OI)</h6>
          <ResponsiveContainer width="90%" height={350}>
            <BarChart data={filteredData}>
              <XAxis dataKey="strike_price" />

              <YAxis
                domain={[
                  minValue >= 0 ? minValue : 0,
                  Math.ceil(maxValue / 1e5) * 1e5,
                ]} // Ensures inclusion of negative values if present
                tickFormatter={(value) => {
                  if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billions
                  if (value >= 1e7) return `${(value / 1e7).toFixed(1)}Cr`; // Crores
                  if (value >= 1e5) return `${(value / 1e5).toFixed(1)}L`; // Lakhs
                  return value;
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="call_oi" fill="#ff7300" name="Call OI" />
              <Bar dataKey="put_oi" fill="#1a8ae6" name="Put OI" />
            </BarChart>
          </ResponsiveContainer>

          <h5>OI Change and Total OI Change</h5>

          <h6>OI Change</h6>
          <ResponsiveContainer width="90%" height={350}>
            <BarChart data={filteredData}>
              <XAxis dataKey="strike_price" hide={true} />
              <YAxis
                domain={yAxisDomain}
                tickFormatter={(value) => {
                  if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billions
                  if (value >= 1e7) return `${(value / 1e7).toFixed(1)}Cr`; // Crores
                  if (value >= 1e5) return `${(value / 1e5).toFixed(1)}L`; // Lakhs
                  return value;
                }}
              />
              <Tooltip />
              <Legend />
              <br></br>
              <Bar
                dataKey="call_oi_diff"
                fill="#ff7300"
                name="Call OI Change"
              />
              <Bar dataKey="put_oi_diff" fill="#1a8ae6" name="Put OI Change" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-group">
          <div className="chart small">
            <h6>Total OI</h6>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={[
                  {
                    name: "Total OI",
                    call_oi: totalCallOI,
                    put_oi: totalPutOI,
                  },
                ]}
                barSize={20}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />

                <Bar
                  dataKey="call_oi"
                  fill="#ff7300"
                  name="Call OI"
                  className="active-bar"
                  onMouseEnter={() => handleMouseEnter("call_oi")}
                  onMouseLeave={handleMouseLeave}
                />

                <Bar
                  dataKey="put_oi"
                  fill="#1a8ae6"
                  name="Put OI"
                  className="active-bar"
                  onMouseEnter={() => handleMouseEnter("put_oi")}
                  onMouseLeave={handleMouseLeave}
                />
              </BarChart>
            </ResponsiveContainer>
            {hoveredBar && (
              <div className="oi-details">
                {hoveredBar === "call_oi" && (
                  <p style={{ color: "#ff7300" }}>Call OI: {totalCallOI}</p>
                )}
                {hoveredBar === "put_oi" && (
                  <p style={{ color: "#1a8ae6" }}>Put OI: {totalPutOI}</p>
                )}
              </div>
            )}
          </div>
          <div className="chart small">
            <h6>Total OI Change</h6>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={[
                  {
                    name: "Total OI Change",
                    call_oi_diff: totalCallOIDiff,
                    put_oi_diff: totalPutOIDiff,
                  },
                ]}
                barSize={20}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Legend />

                <Bar
                  dataKey="call_oi_diff"
                  fill="#ff7300"
                  name="Call OI Change"
                  className="active-bar"
                  onMouseEnter={() => handleMouseEnterr("call_oi_diff")}
                  onMouseLeave={handleMouseLeavee}
                />

                <Bar
                  dataKey="put_oi_diff"
                  fill="#1a8ae6"
                  name="Put OI Change"
                  className="active-bar"
                  onMouseEnter={() => handleMouseEnterr("put_oi_diff")}
                  onMouseLeave={handleMouseLeavee}
                />
              </BarChart>
            </ResponsiveContainer>

            {hovered && (
              <div className="oi-details">
                {hovered === "call_oi_diff" && (
                  <p style={{ color: "#ff7300" }}>
                    Call OI Change: {totalCallOIDiff}
                  </p>
                )}
                {hovered === "put_oi_diff" && (
                  <p style={{ color: "#1a8ae6" }}>
                    Put OI Change: {totalPutOIDiff}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Option Chain Data (Initially hidden, can be toggled) */}
      <div className="option-chain-toggle">
        <button onClick={toggleOptionChainVisibility} className="toggle-button">
          {isOptionChainVisible ? "Hide Option Chain" : "Show Option Chain"}
        </button>

        {isOptionChainVisible && (
          <div className="option-chain-data">
            <table>
              <thead>
                <tr>
                  <th>Strike Price</th>
                  <th>Call OI</th>
                  <th>Put OI</th>
                  <th>Call OI Diff</th>
                  <th>Put OI Diff</th>
                </tr>
              </thead>
              <tbody>
                {optionChainData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.strike_price}</td>
                    <td>{row.call_oi}</td>
                    <td>{row.put_oi}</td>
                    <td>{row.call_oi_diff}</td>
                    <td>{row.put_oi_diff}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <SymbolOiCard />
    </>
  );
};

export default React.memo(Dashboard1);
